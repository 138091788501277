// ==========================================================================
// Object : Slider Adds
// ==========================================================================


.o-slider-adds {
	max-height: 450px;

	&__item {
		position: relative;
		min-height: 225px;
		padding-bottom: 30px;
		@extend .container;

		@media screen and (min-width: $medium-width) {
			min-height: 425px;
		}
	}

	&__img {
		position: absolute;
		bottom: 50px;
		width: 100%;

		@media screen and (min-width: $medium-width) {
			bottom: 0;
		}
	}


	// button link

	.link-container{
		text-align: right;
		position: absolute;
		width: 100%;
		right: 5px;
		bottom: 10px;

		@media screen and (min-width: $medium-width) {
			bottom: 30px;
		}
	}


	// text controler

	.caption-position {
        position: absolute;
        z-index: 10;

		&--top-left {
			text-align: left;
			left: 0;
		}

		&--top-right {
			text-align: right;
			right: 0;
		}

		&--bottom-left{
			bottom: 50px;
			margin-bottom: 10px;
			@media screen and (min-width: $medium-width) {
				margin-bottom: 65px;
				bottom: 0;
			}
		}

		&--bottom-right {
			bottom: 50px;
			margin-bottom: 10px;
			text-align: right;
			@media screen and (min-width: $medium-width) {
				margin-bottom:  65px;
				bottom: 0;
			}
		}    
	}

	.caption {
		color: $white;
		font-family: $font-familty--slider;
		text-transform: uppercase;
		font-size: 30px;
		padding-top: 10px;
		padding-left: 10px;

		text-shadow: 2px 2px 12px rgba(0, 0, 0, 1);

		@media screen and (min-width: $medium-width) {
			font-size: 60px; 
			padding-top: 20px;
			padding-left: 20px;
		}

		@media screen and (min-width: $large-width) {
			font-size: 80px;
		}
        
        span {
            display: block;
            font-size: 16px;
            
            @media screen and (min-width: $medium-width) {
			 font-size: 46px; 
            }

            @media screen and (min-width: $large-width) {
                font-size: 66px;
            }
        }  
    }


	// slick slider element

	.slick-slide {
		outline: 0;
	}

	.slick-arrow {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		//font-size: em(28px);
		//color: $white;
		cursor: pointer;
		z-index: 50;
		outline: 0;
		@media screen and (max-width: $medium-large-width){
			display: inline-block!important;
		}

		&.slick-next {
			right: 0;
			@media screen and (min-width: $large-width) {
				right: 20px;	
			}	 
		}
		&.slick-prev {
			left: 0;
			@media screen and (min-width: $large-width) {
				left: 20px;	
			}	
		}
	}

	.slick-dots {
		width: 100%;
		position: absolute;
		bottom: 0;
		text-align: center;
		padding-bottom: 0;

		li {
			display: inline-block;
			margin-right: 15px;

			&.slick-active {
                cursor: default;
                
                button {
                    cursor: default;
                }
                
				button:before {
					background-color: $white;
				}
			}

			button { 
				position: relative;
				cursor: pointer;
				color: transparent;
				border: 0;
				outline: none;
				background: transparent;

				&:before {
					position: absolute;
					content: "";
					top: 0; left: 0;
					width: 16px;
					height: 16px;
					border-radius: 8px;
					background-color: darken($gray-light, 20%);
					border: 2px solid $color-primary;
                    @extend %transition-base;
				}
			}
            
            @media screen and (min-width: $nohover-width) {
                &:hover {
                    button {
                        &:before {
                            background-color: $white;
                        }
                    }
                }
            }
		}
	} // @end slick dots
}


// background slider 
.slider-section {
	position: relative;
	width: 100%;
	//max-width: 1920px;
	margin: 0 auto;

	box-shadow: 0px 12px 21px 0px rgba(0,0,0,0.6);
	
	background: url('../images/backgrounds/bg_slider_layer_01.jpg') center center;
	background-repeat: repeat-x;

	.grid {
		background: url('../images/backgrounds/bg_slider_layer_03.svg') no-repeat -100px -50px,
					url('../images/backgrounds/bg_slider_layer_02.svg') no-repeat,
	}

	ul {
		overflow: hidden;
		margin: 0 auto;
	}
    
    .container {
        padding-left: 0;
        padding-right: 0;
    }
}



