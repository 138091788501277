.c--popup{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;

    z-index: 100000;
    color:black;

    &.js--is-open{
        display: block;
    }
    .js--prev{
        position: absolute;
        top:10px;
        left: 10px;
        font-size: 20px;
    }
   
    &----bg{
        background: rgba(0,0,0,.5) url("/wp-content/themes/marcor/dist/images/spinner_ajax.svg") no-repeat center center;
        position: absolute;
        top: 0;
        left: 0;
        right:0;
        bottom: 0;
        z-index: -1;
        
    }
    &-box{
        box-shadow: 5px 5px 20px rgba(0,0,0,.5);
        border-radius: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        max-height: 75%;
        max-width: 95%;
        overflow: hidden;
        background: white;
        z-index: 100000;
        padding: 30px;
        min-height: 100px;
        min-width: 100px;
        >span{
            position: absolute;
            right:10px;
            top: 10px;
            font-size:20px;
        }
    }
    &-title{
        font-size: 22px;
        text-align: center;
        margin-top: 50px;
        font-weight: bold;
        margin-bottom: 10px;    
    }
    &-error{
        font-size: 22px;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 10px;    
    }

    &-questions{
        >ol{
            list-style: none;
            >li{
                display: none;
                text-align: center;
                &.js--is-current{
                    display: block;
                }
            }
        }
        .btn{
            width: 100%;
            text-align: center;
            max-width: 400px;
        }
    }

    &-choices{
        
        &----list{
            max-width: 100%;
            width: 1000px;
            white-space: nowrap;
            overflow-x: auto;
            text-align: center;
        }
        &----item{
            display: inline-block;
            margin: 10px;
            text-align: center;
            label{
                position: relative;
                display: block;
                padding: 10px;
                cursor: pointer;
                .fa{
                    position: absolute;
                    display: none;
                    top:50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    font-size: 160px;
                    color:$color-primary;
                }
                &:hover{
                    opacity: 0.9;
                }
            }
            input{
                //display: none;
                position: absolute;
                opacity: 0;
                width: 100%;
                height: 100%;
                &:checked~.fa{
                    display: block;
                }
            }
            img{
                border:1px solid black;
            }
            span{
                display: block;
                margin:15px 0 5px;
                font-size: 18px;
            }
        }
    }
    .has-selector{
        margin: 20px auto;
        text-align: center;
        .select{
            display: inline-block;
            position: relative;
            &:before {
                position: absolute;
                content: '';
                right: 15px;
                top: 26px;
                border-top: 6px solid #000000;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                z-index: 10;
            }
            &:after {
                position: absolute;
                content: '';
                right: 15px;
                top: 15px;
                border-bottom: 6px solid #000000;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                z-index: 10;
            }
            select{
                font-size: 20px;
                padding: 10px 40px 10px 10px;
                border: 1px solid #cacaca;
                border-radius: 5px;
                -webkit-appearance: none;
                -moz-appearance: none;
                text-indent: 1px;
            }
        }
    }
}