.application-manufacturers {
	position: relative;

	@media screen and (min-width: $medium-width) {
		padding-left: 20px;
		padding-right: 20px;
	}

	.alpha-header {
		width: 100%;
		padding: 10px;
		background: $gray-light;
		//border: 2px solid $gray-base;
		border-radius: 5px;

		font-weight: bold;
		font-size: 18px;
		color: $gray-base;
		margin-bottom: 20px;
	}

	.manufacturer {
		position: relative;
		//padding: $spacing-medium-vertical;
		border: 1px solid $gray-light;
		background-color: $white;
		text-align: center;
		margin-bottom: 20px;
		border-radius: 5px;
		min-height: 120px;
		

		@media screen and (min-width: $medium-width) {
			margin-right: 20px;
		}

		&__link {
			position: absolute;
			right: 0;
			top: 0; bottom: 0;
			background-color: $gray-light;
			width: 22px;
			@media screen and (min-width: $nohover-width) {
				&:hover {
					.manufacturer__icon {
						transform: translate3d(-3px,-50%,0);
					}
				}
			}
		}

		&__icon {
			position: absolute;
			top: 50%; right: 2px;
			transform: translateY(-50%);
			font-size: 1.2em;
			transform: translate3d(0,-50%,0);
			@extend %transition-base;
		}

		&__name {
			display: block;
			margin-top: 5px;
		}

		label {
			position: absolute;
			left: 0; right: 0;
			top: 0; bottom: 0;
			width: 100%;
			cursor: pointer;
			padding-top: 20px;
			padding-bottom: 20px;
			padding-right: 20px;
			padding-left: 10px;
		    @extend %transition-base;
		    box-shadow:         2px 2px 17px 0px rgba(50, 50, 50, 0);

		    @media screen and (min-width: $nohover-width) {
		        &:hover {
		            box-shadow:         2px 2px 17px 0px rgba(50, 50, 50, 0.5);
		        }        
		    }
			input[type='checkbox'] {
				position: absolute;
				top: 5px; left: 5px;
				transform: scale(1.2)
			}
			
		}

		a.selector_part.select-manufacturer-parttypes {

			display: block;
		    font-size: $base__font-size;
		    vertical-align: middle;

		    i {
		    	padding: 5px;
		    }
		}
	}
}

.application-manufacturers .alpha-header { clear:both; }
.application-manufacturers .manufacturer img { width:71px; height:40px; }

.global-tooltip {
	position:relative;
	background:#ffffff; 
	border:solid 1px #bbb; 
	font-size:0.9em; 
	border-top: 20px solid $color-primary;
	border-radius: 5px;
	max-width: 280px;

	@media screen and (min-width: $medium-width) {
		max-width: none;
	}
	
	[type="checkbox"] {
		float: left;
	}

	ul { margin:0 0 20px; padding:0; }

	li {
		list-style:none;
		position: relative;
	}

	&__label {
		display: block;
		margin-left: 20px;
	}

	&__check {
		position: absolute;
		top: 0;left: 0;
		margin-top: 3px;
	}

	.fa {
		position: absolute;
	    top: 3px; right: 10px;
	    font-size: 1.3em;
	    cursor: pointer;
	}
	&__content {
		padding: 20px 20px 0;
		overflow: auto;
	}
        .js-close-tooltip {
		position:absolute;
		top:-19px;
		right:4px;
		color:#fff;
	}
}