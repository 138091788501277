.partial-title {
	position: relative;
	width: auto;
	display: inline-block;
	max-width: 100%;
	left: 50%;
	min-width: 0;
	transform: translate(-625px,0);
	padding: 0!important;
	font-weight: bold;
	@media screen and (max-width: $medium-large-width) {
		left: 75px;
		transform: none!important;
	}
	&:before {
		content: '';
		background: $color-primary;
		height: 25px;
		width: 5000px;
		left: 0;
		top: 50%;
		transform: translate(calc(-100% - 30px), -50%);
		border-radius: 100px;
		position: absolute;
	}
	&:after {
		content: '';
		background: $color-primary;
		height: 25px;
		width: 5000px;
		right: 0;
		top: 50%;
		transform: translate(calc(100% + 30px),-50%);
		border-radius: 100px;
		position: absolute;
	}

	&__static-page{
		position: absolute;
		text-align: center;
		font-family: $font-family--primary;
		color: #474747;
		font-weight: 600;
		letter-spacing: 3px;
		padding-bottom: 20px;
		font-size: 30px!important;
		text-transform: none!important;
		transform: translate(-625px,0);
	}
}
.site-page {
	@extend %clearfix;
	padding: 0px;
	padding-top: 10px;
	h2{
		color: $shark;
		padding-top: 10px;
		padding-bottom: 10px;
	}
	&__banner {
		
		background-position: left;
		background-repeat: no-repeat;
    	background-size: cover;
    	height: 150px;
    	@media screen and (min-width: $medium-width) {
    		height: 300px;
    		
    	}
    	@media screen and (min-width: $medium-width) {
    		height: 370px;
    		background-position: center;
    	}
	}
	.container{
		p{
			color: $black;
			font-family: $font-family--secondary; 
			
		}
	}
	.container-asset, .container-video{
		margin-top: 60px;
		font-size: 12px;
		font-family: $font-family--secondary;
		@media screen and (min-width: $medium-width) {	
			margin-top: 40px;
		}
		a:hover{
			text-decoration: none;
		}
		h2{
			border-bottom: 1px solid $color-primary;
			padding-bottom: 0px;
			margin-bottom: 20px;
		}
		img{
			width: 100%;
			margin-bottom: 20px;
		}
		.fa{
			vertical-align: middle;
			margin-right: 10px;
			margin-bottom: 10px;
			float: left;
		}
	}
	 .container-content, .wysiwyg-content{
		p{
			color: $black;
			font-family: $font-family--secondary;
		}
	}
	.container-insertion-fluid{
		padding: 40px 0;
		margin-bottom: 40px;
			@media screen and (min-width: $medium-width) {				
				margin-bottom: inherit;
				background: url('/dist/images/stripe-gray.png');
				background-repeat: repeat no-repeat;
				background-position: center center;
			}
		img{
			width: 100%;
			padding-bottom: 10px;
			 @media screen and (min-width: $medium-width) {
				padding-bottom: inherit;
			}
		}
	}
	.container-insertion{
		@media screen and (min-width: $medium-width) {
			display: flex;
    		align-items: center;
    	}
	}
	.container-introduction{
		margin-bottom: 20px;
	}
	.container-main{
		margin-top: 20px;
	}
	.container-pre-footer{
		img{
			margin: auto;
			display: none;
			@media screen and (min-width: $medium-width) {
				display: block;
				width: 100%;
			}
			@media screen and (min-width: $medium-width) {
				width: 100%;
			}
		}
	}
	&__content{
		padding: 50px 0;

		ul, ol{
			font-family: $font-family--secondary;
			color: $black;
			margin-left: 30px;
		}
		.sitemap{
			h2{
				padding-bottom: 5px
			}	
			li{
			    background-size: 25px;
			    padding-left: 30px;
			    list-style: none;
			    margin-bottom: 15px;
			    a{
			    	text-decoration: underline;
			    }
			}
		}

	}

	.insertion__text{
		text-align: center;
		background-color: $shark;
		padding: 10px;
		@media screen and (min-width: $medium-width) {
			text-align: left;
			background-color: transparent;
			padding: 0;
		}
		@media screen and (min-width: $medium-width) {
			text-align: left;
		}
		h2{
			color: $color-primary;
			font-size: 25px;
			font-weight: bold;
			padding-bottom: 0;
		}
		p{
			color: $white;
			font-family: $font-family--primary;
			font-size: 20px;
		}
	}
	.insertion__image{
		padding-bottom: 20px;
		@media screen and (min-width: $medium-width) {
			padding-bottom: 0;
		}
	}
	
	&__faq{
		.faq-answer{
			display: none;
			margin-top: 20px;
			margin-left: 30px;
		}
		.faq-question{
			background-color: #DCDCDC;
    		padding: 10px;
		    font-size: 20px;
		    font-weight: 400;
		    color: #313236;
			margin-top: 20px;
			font-family: $font-family--secondary;
			&:hover{
				cursor: pointer;
			}
		}
		h2{
			margin-top: 20px;
			padding-bottom: 0;
		}
	}
	&.promo{
	.site-page__title{
		padding: 80px 0;
	}

	}
	&__title {
		color: $black;
		text-align: center;
	    font-family: 'Poppins',"Helvetica Neue",Helvetica,Arial,sans-serif;
	    text-transform: uppercase;
	    font-weight: 700;
	    letter-spacing: 3px;
	    padding: 30px 0;
	    font-size: 40px;
	}
	&__white {
		background-color: $white;		
	}

}




