.video-thumb {
  width: 100%;
  padding: 5px;
  iframe{
    position: absolute;
  }
 }

  @media screen and (min-width: 1069px) {
    .video-thumb {
      width: auto;
      position: absolute;
      left:200px;
      z-index: 3;
      bottom: 3px;
      iframe{
        position:static;
      }
      .ratio-container-16x9{
        padding-bottom: 0;
        padding-top: 56.25%;
      }
     }
  }
  .btn-close{
    position: absolute;
    color: white;
    font-size: 35px;
    font-weight: 700;
    right:-25px;
    top:-30px;
    cursor: pointer;
  }
  .frame,
  .modal-wrapper,
  .modal-content,
  .modal-content iframe,
  .ratio-container-16x9,
  .video-thumb-overlay,
  .video-thumb iframe
   {
    width: 100%;
    height: 100%;
  }
  
  .video-thumb-overlay {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
 .ratio-container-16x9 {
      pointer-events: auto;
      position: relative;
      padding-bottom: 56.25%;
  }
  .modal-content iframe{
    position: absolute;
  }
  .modal-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .video-modal {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1050;
      overflow: hidden;
      display:none;
      background-color: rgba(0,0,0,0.6);
  }
  .modal-dialog {
      margin: 10px;
      pointer-events: none;
      left:0;
      right: 0;
      width: 100%;
      max-height: 90%;
  }
  @media (min-width: 992px) { 
    .modal-dialog{
      width: 80%;
    }
   }