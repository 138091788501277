.ajax-loading { 
	position:fixed; 
	left:0; 
	top:0;
	width:100%; 
	height:100%; 
	display:none; 
	z-index: 9999;

	.lock { 
		position:fixed; 
		left:0; 
		top:0; 
		width:100%; 
		height:120%; 
		display:block; 
		background:transparent; 
	}

	.overlay { 
		position:fixed; 
		left:0; 
		top:0;
		width:100%; 
		height:120%;
		 display:block; 
		 background:#000102;
		 opacity:0.5; 
	}

	.content { 
		display:table;
		height: 100%;
		width: 100%; 
		font-size:0.9em; 

		&__align {
			display: table-cell;
			width: 100%;
			height: 100%;
			vertical-align: middle;
			text-align: center;
		}
	}

	p { 
		width:160px; 
		margin:auto; 
		padding:10px 20px; 
		text-align:center; 
		color:#252a2d; 
		background:#e2e6e9; 
		border:#252a2d solid 1px; 
		border-radius:7px;
		opacity:0.9; 
	}

	img,
	svg {
		position: relative;
		z-index: 99999;
		
		width : 15%; 
		height: 15%;
		margin: 0 auto;
	}
}