 .container-partial {
     overflow: hidden;
    &--step-breadcrumb {
        &.sticky-nav{
            background: $white url(../images/pasuper/lines.svg) no-repeat;
            background-position: calc(50% - 720px) top;
            background-size: 160px;

            .breadcrumb{
                min-height: 35px;
            }
        }
        padding: 5px!important;
        //background-color: $white;
        //width: 100%;
        //margin: 0 30px;
        .btn {
            margin-top: 0!important;
        }
        &__right-block{
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 148px;
            &:before{
                content: ' ';
                background: #000;
                width: 3000px;
                height: 5px;
                position: absolute;
                left: 0;
                transform: translate(calc(-100% - 5px),-50%);
                top: 50%;
            }
        }
        .view-cart{
            cursor: pointer;
            position: relative;
            text-decoration: none;
            font-size: 1em;
            font-weight: 700;
            text-align: center;
            background: $color-primary;
            color: $white;
            border-radius: 50px;
            transform-style: preserve-3d;
            overflow: hidden;
            width: 200px;
            margin-left: -70px;
            z-index: 3;
            display: block;
            padding: 15px 0;
        }
        .btn-results {
            width: 200px;
            margin-left: -70px;
            z-index: 3;
            position: relative;
            padding: 15px 0;
        }
        .btn-reset{
            &.disabled{
                color: $gray-light!important;
                &:after{
                    border-color: $gray-light;
                }
            }
        }
        .breadcrumb-text{
            color: $black;
            width: auto;
            float: left;
            background: $white;
            top: 50%;
            position: absolute;
            transform: translate(0, -50%);
            display: block;
            z-index: 1;
            border: 1px solid $gray-light;
           list-style: none;
            li{
                display: inline;
                &.current{
                    a{
                        color:$color-primary;
                        cursor: default;
                        &:hover{
                            background: transparent;
                            &:after {
                                border-left-color: $white !important;
                            }
                        }
                    }
                }
                &:not(.current){
                    span{
                        color: $black !important;
                    }
                }
            }

            a{
                padding: 5px;
                color: #333;
                font-size: 14px;
                margin-left: 10px;
                display: none;
                position: relative;
    
                &:first-child{
                margin-left: 0;
                display: inline-block
                }
                &:nth-last-of-type(2){
                display: inline-block
                }

                &:before,&:after{
                content: " ";
                width: 0;
                height: 0;
                border-top: 15px solid transparent;
                border-bottom: 15px solid transparent;
                position: absolute;
                top: 50%;
                margin-top: -15px;
                /*: 100%;*/
                display: block;
                transform: translateX(-1px);
                }
                &:before {
                border-left: 10px solid $gray-light;
                margin-left: 1px;
                z-index: 1;
                transition: .3s;
                right: -11px;
                }
                &:after {
                border-left-color: $white!important;
                border-left: 10px solid $gray-light;
                z-index: 2;
                right: -10px;
                transition: .3s;
                overflow: hidden;
                }

                @media screen and (min-width: $medium-width){
                display: inline-block
                }
                [data-tooltip]{
                    @media (max-width: $small-width){
                    max-width: 128px;
                    display: inline-block;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    vertical-align: bottom;
                    }
                }
            }
            li+li{
                margin-left: -5px;
                a{
                    padding-left: 21px;
                }
            }
            a{
                padding-left: 16px;
                text-decoration: none;
                transition: .3s;
                &:hover{
                    background: #eee;
                    //color:$white;
                    &::after{
                        border-left-color: #eee !important;
                    }
                }
            }
            
            /*span{
                &:first-child{
                    a{
                        border-width: 1px 0 1px 1px;
                        margin: 0;
                    }
                }
                margin-right: 20px;
                position: relative;
               a{
                   border-width: 1px 0;
                   border-color: #d4d4d4;
                   font-size: 14px;
                   padding: 6px 6px 6px 20px;
                   margin-left: -20px;
                   @extend %transition-base;


                   &:before {
                       border-left: 10px solid #d7d7d7;
                       margin-left: 1px;
                       z-index: 1;
                       transition: .3s;
                   }
                   &:after{
                       border-left-color: #fff!important;
                       border-left: 10px solid #d4d4d4;
                       z-index: 2;
                       transition: .3s;
                       overflow: hidden;

                   }
                   &:before,&:after{
                       content: " ";
                       width: 0;
                       height: 0;
                       border-top: 15px solid transparent;
                       border-bottom: 15px solid transparent;
                       position: absolute;
                       top: 50%;
                       margin-top: -15px;
                       left: 100%;
                       display: block;
                       transform: translateX(-1px);
                       @extend %transition-base;

                   }
                   &:hover{
                       background: #eee;
                       text-decoration: none;
                       &:after {
                           border-left-color: #eee!important;
                       }
                   }
               }
                &:last-child{
                    color: $color-primary;
                    border-width: 1px 0 1px 0;
                    border-color: #d4d4d4;
                    font-size:14px;
                    padding: 6px 6px 6px 20px;
                    margin-left: -25px;
                    &:before {
                        border-left: 10px solid #d7d7d7;
                        margin-left: 1px;
                        z-index: 1;
                        transition: .3s;
                    }
                    &:after{
                        border-left-color: #fff!important;
                        border-left: 10px solid #d4d4d4;
                        z-index: 2;
                        transition: .3s;
                        overflow: hidden;

                    }
                    &:before,&:after{
                        content: " ";
                        width: 0;
                        height: 0;
                        border-top: 15px solid transparent;
                        border-bottom: 15px solid transparent;
                        position: absolute;
                        top: 50%;
                        margin-top: -15px;
                        left: 100%;
                        display: block;
                        transform: translateX(-1px);
                        @extend %transition-base;

                    }
                }
            }*/

        }
        .button-save-vehicle {
            position: relative;
            text-align: left!important;
            color: $white;
            font-family: $font-family--secondary;
            font-size: 16px;
            padding-top: 10px;
            font-weight: bold; 
            z-index: 1;
            top: 37px;
            &:hover{
                text-decoration: underline;
                cursor: pointer;
            }
            @media screen and (min-width:$medium-width) {
                position: absolute;
                bottom: 5px;
                left: 0;
                right: 0;
                top: inherit;
                font-size: 12px;
             }
              @media screen and (min-width: $medium-width) {
                color: $shark;
              }
            @media screen and (min-width: 1250px) {
                margin-top: 10px;
                text-align: right;
                position: relative;
                bottom: 0;
            }
            i {
                padding-right: 5px;
            }
        }
        .breadcrumb {
            overflow: hidden;
            text-align: center;
            padding: 0;
            z-index: 2;
            position: relative;
            padding-bottom: 20px;
            min-height: 70px;
            max-width: 1254px;
            margin: auto!important;
            @media screen and (min-width: 1250px) {
                margin: 20px 0;
            }

            .container-breadcrumb-line{
                display: none;
                z-index: -1;
                position: relative;
                left: 177px;
                top: 33px;
                @media screen and (min-width: 1250px) {
                    display: block;
                }

                hr {
                   height: 4px;
                    background: $color-primary;
                    margin: auto;
                    width: 100%;
                    float: left;
                    
                   &.dotted{
                        border-top:4px dashed $gray-light;
                        background: transparent;
                    }
                }
            }


            .container-step {
                margin-bottom: 2px;
                padding: 2px;
              
                
                @media screen and (min-width: 1250px) {
                    padding: 0 20px;
                    margin-bottom: 5px;
                    left: 80px;
                    position: relative;
                }
               
                 &.disabled {
                    .step-click-zone:hover{
                        cursor: inherit;
                    }
                    .step {
                        background-color: #c9c9c9;
                    }
                    .step-number {
                        background-color: $gray-base;
                    }
                }
                &.active {
                    .step-number {
                        background-color: $color-primary;
                    }
                    .step-click-zone:hover{
                        cursor: inherit;
                    }
                    .dotted{
                        border-top:4px dashed $color-primary;
                    }    
                 } 
                 &.next {
                     .container-content-step:hover{
                        cursor: pointer;
                    }
                    .step{
                        background-color: $color-primary;
                    }
                    .step-number{
                        background-color: $shark!important; 
                    } 
                }
            }
            .container-step-save{
                background-color: $color-primary;
                height: 50px;
            }
            .step-click-zone{
                height: 100%;
                width: 100%;
                position: absolute;
                z-index: 2;
                @media screen and (min-width: $medium-width) {
                    width: 58%;
                }
                &:hover{ 
                    cursor: pointer;
                }
            }
            .step {
                background-color: $shark;
                color: $white;
                padding: 5px;
                height: 80px;
                border-radius: 100px;
                @media screen and (min-width:$medium-width) {
                    height: 140px;
                }
                @media screen and (min-width: 1250px) {
                    padding: 5px;
                    width: 220px;
                    height: 70px;
                }
                .container-content-step{
                    top: 50%;
                    position: relative;
                    transform: translateY(-50%);
                    @media screen and (min-width:$medium-width) {
                        position: absolute;
                        bottom: 25px;
                        top:inherit;
                        transform: inherit;
                        left: 0;
                        right: 0;
                    }
                    @media screen and (min-width: 1250px) {
                        position: relative;
                        transform: translateY(-50%);
                        top: 50%;
                    }
                 
                    hr{
                        height: 1px;
                        background: rgba(255,255,255,.5);
                        margin: auto;
                        width: 50%;
                        margin-top: 5px;

                        @media screen and (min-width:$medium-width) { 
                            width: 80%;
                        }
                    }

                }
                &-number {
                    display: inline-block;
                    width: 20px;
                    margin-right: 0;
                    background-color: $gray-base;
                    color: $white;
                    font-size: 20px;
                    width: 55px;
                    height: 55px;
                    border-radius: 50%;
                    float: left;
                    position: relative;
                    z-index: 2;
                    position: absolute;
                    left: 10px;
                    bottom: 10px;
                    line-height: 20px;
                    @extend %transition-base;
                    @media screen and (min-width:$medium-width) {
                        margin: auto;
                        left: 0;
                        right: 0;
                        bottom: inherit;
                        font-size: 15px;
                        line-height: 15px;
                        width: 45px;
                        height: 45px;
                        margin-top: 5px;
                    }
                     @media screen and (min-width: 1250px) {
                       left: -40px;
                       right: inherit;
                       margin: 0;
                       bottom: -1px;
                       font-size: 20px;
                        width: 55px;
                        height: 55px;
                        line-height: 20px;
                        width: 65px;
                        height: 65px;
                    }
                    &-hover{
                        background-color: $color-primary;
                        @extend %transition-base;
                    }
                    
                }
            }
            .fa-shopping-cart{
                @media screen and (min-width: $medium-width) {
                    padding-top: 6px; 
                }   
             }
            &--text-step {
                overflow: hidden;
                padding-top: 5px;
                min-height: 20px;

                @media screen and (min-width: 1250px) {

                }
                &-number {
                    font-weight: bold;
                    transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    position: relative;
                    top: 55%;
                    font-size: 16px;
                   
                }
            }
            &--subtitle-step {
                display: block;
                font-weight: bold;
                text-transform: uppercase;
                font-family: $font-family--secondary;
                font-size: 13px;
                 @media screen and (min-width: $medium-width) {
                    font-size: 15x;
                }
                @media screen and (min-width: 1250px) {

                }
            }
        }
        @media screen and (max-width:$medium-width) {
            .js-step3-breadcrumb{
                margin-top: 25px;
            }
            .breadcrumb-text{
                position: relative;
                transform: none;
                background: none;
                float:none;
                margin-top: 23px;
                background: transparent;
            }
            .container-partial--step-breadcrumb__right-block {
                position: relative;
                transition: none;
                right: 0;
                width: 100%;
                margin-top: 52px;
            }
            .view-cart{
                position: relative;
                left: 0;
                display: block;
                margin: auto;
                top: 15px;
            }
            background:transparent!important;
        }
    }
}  

.save-overlay{
    position: absolute;
    left: 0;
    width: 100%; 
    height: 235px;
    background-color: rgba(166, 166, 166, .5);
    z-index: 4;
    -webkit-transition: all 620ms ease;
    transition: all 620ms ease;
   &-loading {
        position: absolute;
        text-align: center;
        display: block;
        z-index: 100;
        font-size: 60px;
        opacity: 1;
        color: #fff;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
    }
    &-message {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 100;
        opacity: 1;

        .overlay-box {
            top: 50%;
            transform: translateY(-50%);
            background-color: #fff;
            min-height: 100px;
            width: 350px;
            margin: auto;
            font-family: $font-family--secondary;
            vertical-align: middle;
            position: relative;
            padding: 15px;
            border-radius: 2px;
            .success {
                color: #313236;
                text-align: center;
            }
            .error {
                color: $color-primary;
                text-align: justify;
            }
        }
    }
}
 @media screen and (max-width:$medium-width) {
     .container-partial--step-breadcrumb{
         display: none;
     }
 }