
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url(/dist/fonts/Open_Sans-normal-400.woff) format('woff');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 800;
	src: url(/dist/fonts/Open_Sans-normal-800.woff) format('woff');
}

