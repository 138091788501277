body {
	font-family: $font-family--primary;
	font-size: $base__font-size;
	color: $base__font-color;
	line-height: $base__line;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	
}

h1, .h1 {

}

h2, .h2 {
	font-size: em(30px);
}

h3, .h3 {
	font-size: em(18px);
	color: $gray-light;
	text-transform: uppercase;

	margin-bottom: em(5px, 18px);

	@media screen and (min-width: $small-width) {
		margin-bottom: em(25px, 18px);
	}	
}

.sidebar h4 {
	 display:inline-block;
	 font-weight: normal;
}

p {
    color: $white;
}

a {
	color: inherit;
	text-decoration: none;

	@media only screen and (min-width: $nohover-width) {
		&:hover {
			text-decoration: underline;
		}
	}

}

strong, .strong {
	font-weight: $font-weight--bold;
}

.reset {
	display: inline-block;
	font-size: 12px;
	text-transform: uppercase;
	float: right;
}
// wysiwyg stylesheet
.wysiwyg-container {
	p {
		
	}

	a {

	}
}

@font-face {
    font-family: 'freestyle_scriptregular';
    src: url('../../vendor/fonts/FreeStyleScript/freescpt-webfont.eot');
    src: url('../../vendor/fonts/FreeStyleScript/freescpt-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../vendor/fonts/FreeStyleScript/freescpt-webfont.woff2') format('woff2'),
         url('../../vendor/fonts/FreeStyleScript/freescpt-webfont.woff') format('woff'),
         url('../../vendor/fonts/FreeStyleScript/freescpt-webfont.ttf') format('truetype'),
         url('../../vendor/fonts/FreeStyleScript/freescpt-webfont.svg#freestyle_scriptregular') format('svg');
    font-weight: normal;
    font-style: normal;

}


