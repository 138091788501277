.container-news{
    
    &__article {
       
      .container-link-back{
            padding-bottom: 20px;
            a{
                background-size: 25px;
                padding-left: 30px;
                background-position: center left;
                color: $color-primary;
            }
      }
      .wysiwyg-content{
            font-color: $shark;
            margin: 50px 0;

            p{
                margin: 10px 0;
            }
            ul, ol{
                margin: 10px 0;
                padding-left: 20px;
                >li{
                    margin: 10px 0 0;
                    &:first-child{
                        margin: 0;
                    }
                }
            }
        }
    }

    
    &--list {
        article {
            padding-bottom: 20px;
            border-bottom: 1px solid $gray-light;
            margin-bottom: 20px;
        }
        .wysiwyg-content{
            font-color: $shark;
            margin: 50px 0;
        }
    }
    
} 