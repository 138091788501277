.pagination {
	text-align: center; 
	ul{
		list-style: none;
		li{
			display: inline-block;
			padding: 10px;
			&.active{
				font-weight: bold;
			}
		}
	}

}