// ==========================================================================
// Helpers
// ==========================================================================

// Clearfix
input[type='checkbox'],label { cursor: pointer; }
%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
span.select2-dropdown.select2-dropdown--below {
	//margin-top: -18px;
}
.clearfix {
	@extend %clearfix;
}

//hide select2 labels selections
li.select2-selection__choice {
	display: none;
}

// Center with bootstrap

%col-center {
	float: none;
	margin: 0 auto;
}

.col-center {
	@extend %col-center;
}

.bg-primary{
	background-color: $color-primary;
}

// Screen reader text

.srt {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

// Images

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

// Position
	.inlined { display: inline-block; }
    .blocked { display: block; }


// Width

	.quarter { width: 25%;}
	.fifty   { width: 50%;}
	.seven   { width: 75%;}
	.full    { width: 100%!important;}


// Align
.centered {
	float: none;
	margin-left: auto;
	margin-right: auto;
}

.font-family--primary{
    font-family: $font-family--primary;    
}
.font-family--secondary{
    font-family: $font-family--secondary; 
}
.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}
.text-bold{
    font-weight: bold;
}
.text-italic{
    font-style: italic;
}
.vertical-center{
    top: 50%;
    position: relative;
    transform: translateY(-50%);

}
// Modifiers
.text-uppercase {
	text-transform: uppercase;
}

.white {
	color: $white!important; 
}
.color--secondary{
    color: $shark;
}

.color--primary{
    color: $color-primary!important;
}
.color--gray-base{
    color: $gray-base;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.overflow-hide {
  overflow: hidden;
}

.hidden-desktop
{
	display: none;
	@media screen and (max-width: $medium-width) {
		display: block;
	}
}

.overflow-show {
  overflow: visible;
}

.invisible {
  visibility: hidden;
}

// For pseudo-elements

%pseudos {
  display: block;
  content: ''; 
  position: absolute;
}


// Transition
%transition-base {
    transition: all 240ms ease;
}

%transition-slow {
    transition: all 480ms ease;
}

%transition-xslow {
    transition: all 620ms ease; 
}

%transition-xxslow {
    transition: all 840ms ease; 
} 


// Remove default style of list

%unlist {
	list-style-type: none;
}


.vcenter {
	display: table;
    width: 100%;
    height: 100%;
}

.vcenter-element {
	display:table-cell;
	vertical-align:middle;
    width: 100%;
    height: 100%;

    &--padding {
    	padding: 10px 0;
    }
}


// Table display
%table {
	display: table;
}

%table-cell {
	display: table-cell;
}

// Spacing
// Exemple: <p class="spacing--xxlarge spacing--0bottom">

.spacing {

	// Modifiers

	&--base {
		padding-top: $spacing-base-vertical;
		padding-bottom: $spacing-base-vertical;
	}

	&--medium {
		padding-top: $spacing-medium-vertical;
		padding-bottom: $spacing-medium-vertical;
	}

	&--large {
		padding-top: $spacing-large-vertical;
		padding-bottom: $spacing-large-vertical;
	}

	&--xlarge {
		padding-top: $spacing-xlarge-vertical;
		padding-bottom: $spacing-xlarge-vertical;
	}

	&--xxlarge {
		padding-top: $spacing-xxlarge-vertical;
		padding-bottom: $spacing-xxlarge-vertical;
	}

	&--jumbo {
		padding-top: $spacing-jumbo-vertical;
		padding-bottom:$spacing-jumbo-vertical;
	}

	&--0top {
		padding-top: 0;
	}

	&--0bottom {
		padding-bottom: 0;
	}
	&--0left {
		padding-left: 0;
	}
	&--0right {
		padding-right: 0;
	}
	
}

// Spacing
/*
.m0  { margin:        0 }
.m0--imp  { margin:   0!important;}
.mt0 { margin-top:    0!important;}
.mr0 { margin-right:  0!important;}
.mb0 { margin-bottom: 0!important;}
.ml0 { margin-left:   0!important;}

.m1  { margin:        $spacing-base; }
.mt1 { margin-top:    $spacing-base!important; }
.mr1 { margin-right:  $spacing-base; }
.mb1 { margin-bottom: $spacing-base; }
.ml1 { margin-left:   $spacing-base; }

.m2  { margin:        $spacing-medium; }
.mt2 { margin-top:    $spacing-medium; }
.mr2 { margin-right:  $spacing-medium; }
.mb2 { margin-bottom: $spacing-medium; }
.ml2 { margin-left:   $spacing-medium; }

.m3  { margin:        $spacing-large; }
.mt3 { margin-top:    $spacing-large; }
.mr3 { margin-right:  $spacing-large; }
.mb3 { margin-bottom: $spacing-large; }
.ml3 { margin-left:   $spacing-large; }

.m4  { margin:        $spacing-xlarge; }
.mt4 { margin-top:    $spacing-xlarge; }
.mr4 { margin-right:  $spacing-xlarge; }
.mb4 { margin-bottom: $spacing-xlarge; }
.ml4 { margin-left:   $spacing-xlarge; }


.m5  { margin:        $spacing-xxlarge; }
.mt5 { margin-top:    $spacing-xxlarge; }
.mr5 { margin-right:  $spacing-xxlarge; }
.mb5 { margin-bottom: $spacing-xxlarge; }
.ml5 { margin-left:   $spacing-xxlarge; }

.p0  { padding: 0!important;}

.pt0 { padding-top:0; }
.pt2 { padding-top: 20px; }
.pb2 { padding-bottom: 20px; } 
.pb0 { padding-bottom:0!important; }

.p1  { padding:       $spacing-base!important; }
.py1 { padding-top:   $spacing-base; padding-bottom: $spacing-base; }
.px1 { padding-left:  $spacing-base; padding-right:  $spacing-base; }

.p2  { padding:       $spacing-medium; }
.py2 { padding-top:   $spacing-medium!important; padding-bottom: $spacing-medium; }
.px2 { padding-left:  $spacing-medium!important; padding-right:  $spacing-medium!important; }

.p3  { padding:       $spacing-large; }
.py3 { padding-top:   $spacing-large; padding-bottom: $spacing-large; }
.px3 { padding-left:  $spacing-large; padding-right:  $spacing-large; }

.p4  { padding:       $spacing-xlarge; }
.py4 { padding-top:   $spacing-xlarge; padding-bottom: $spacing-xlarge; }
.px4 { padding-left:  $spacing-xlarge; padding-right:  $spacing-xlarge; }*/
@for $i from 0 through 5{
	/*.m#{$i}{
		margin:   0!important;
	}*/
	$spaceVal: 0;
	@if $i == 1{ $spaceVal:$spacing-base; }
	@else if $i == 2{ $spaceVal:$spacing-medium; }
	@else if $i == 3{ $spaceVal:$spacing-large; }
	@else if $i == 4{ $spaceVal:$spacing-xlarge; }
	@else if $i == 5{ $spaceVal:$spacing-xxlarge; }
	
	.m#{$i}{ margin: $spaceVal !important;}
	.mt#{$i}{ margin-top: $spaceVal !important;}
	.mr#{$i}{ margin-right: $spaceVal !important;}
	.mb#{$i}{margin-bottom: $spaceVal !important;}
	.ml#{$i}{margin-left: $spaceVal !important;}
	.mx#{$i}{margin-left: $spaceVal !important; margin-right: $spaceVal !important;}
	.my#{$i}{margin-top: $spaceVal !important; margin-bottom: $spaceVal !important;}
	.p#{$i}{padding: $spaceVal !important; }
	.pt#{$i}{padding-top: $spaceVal !important;}
	.pr#{$i}{padding-right: $spaceVal !important; }
	.pb#{$i}{padding-bottom: $spaceVal !important; }
	.pl#{$i}{padding-left: $spaceVal !important;}
	.px#{$i}{padding-left: $spaceVal !important; padding-right: $spaceVal !important;}
	.py#{$i}{padding-top: $spaceVal !important; padding-bottom: $spaceVal !important;}
}

.bordered {
	border: 1px solid $gray-base;
	padding-top: 15px;
	padding-bottom: 15px; 
}
.border-top-white{
    border-top: 10px solid $white;
}
.row-centered {
    text-align:center;
}
.col-centered {
    display: inline-block;
    float: none;
}

.flex{
	flex-direction: row;
	flex-wrap: wrap;
	display: flex;
	align-content: stretch;
	align-items: stretch;
	justify-content: flex-start;


	&-column{
        flex-direction: column;
    }

    &-center{
        align-items:center;
    }

    &-bottom{
        align-items:flex-end;
    }

    &-no_wrap{
        flex-wrap: nowrap;
    }
    &-j-end{
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
	}

	&--space-around{
		justify-content: space-around;
	}

	&--space-evenly{
		justify-content: space-evenly;
	}

    &-j-center{
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center!important;
    }
	&--mobile-column{
		@media screen and (max-width: $medium-width) {
			flex-direction: column!important;
		}
	}

    >*{
		flex-grow: 0;
		flex-shrink: 1;
		order: 0;
		flex-basis: auto;
		align-self: auto;
		float: none;
        &.grow{
            flex-grow: 1;
        }
        &.align-center{
            align-self:center;
        }
        &.align-bottom{
            align-self:flex-end;
        }
        &.align-stretch{
            -webkit-align-self: stretch;
            -ms-flex-item-align: stretch;
            align-self: stretch;
        }
        &.fixed{
            flex: none;
		}
	}
}

.fixed-element {
    position:fixed;
    top:0;
    z-index:200;
}

.multi-columns{
	.btn{
		&:after{
			content: none !important;
		}
	}
	columns: 2;
   	-webkit-columns: 2;
	-moz-columns: 2;
	column-gap: $spacing-medium-vertical;

	@media screen and (min-width:$small-width) {
		columns: 3;
   		-webkit-columns: 3;
		-moz-columns: 3;
	}

	@media screen and (min-width:$medium-width) {
		columns: 5;
    	-webkit-columns: 5;
		-moz-columns: 5;
	}
}
ul.multi-columns{
	list-style-type: none;
	li{
		margin: 0 10px;
		@media screen and (min-width:$small-width) {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			height: 30px;
		}
	}
	a:not(.btn){
		display: inline-block;
		min-width: 100%;
		margin: 5px 0;
		&:before{
			content:"";
			display: inline-block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 7px 0 7px 7px;
			border-color: transparent transparent transparent $color-primary;
			vertical-align: baseline;
			margin-right: 5px;
		}
	}

	@media screen and (max-width:$small-width - 1px) {
		columns: auto;
   		-webkit-columns: auto;
		-moz-columns: auto;
		display: flex;
		flex-wrap: wrap;
		column-gap: 0;
		li{
			width: 50%;
			margin: 0;
			padding: 0 10px;
		}
	}
}

.jobi{
    &-lg{
        font-size: 60px;
    }
}
