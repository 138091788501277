.display-none{
    display: none!important;
}

.hidden-onload{
    display: none;
}

input[type=submit],button{
    -moz-appearance: none;
    -webkit-appearance: none;
}