.container-contact {
	font-family: $font-family--secondary;
	button{
		width: 100%;
		@media screen and (min-width:$medium-width) { 
			width: 50%;
			margin-left: auto!important;
			margin-right: auto!important;
		}
		@media screen and (min-width: $medium-width) {
    		float: right;
    		width: 100%;
    	}
    }
    .contact-form-success {
		max-width: 400px;
        text-align: center;
        margin: auto;
        margin-bottom: 20px;
        padding: 5px;
        background-color: $color-secondary;
        color: $white;
    }
    .container-address{
    	text-align: left;
    	padding-bottom: 20px;
    	
    	@media screen and (min-width: $medium-width) {
    		text-align: left;
    		padding-bottom: 0;
    	}
    	button{
    		float: none;
    		display: block;
    		@media screen and (min-width:$medium-width) {
    			
    		}
   		 }
   		 h2{
   		 	padding-bottom:10px; 
   		 }
    }	
	.container-content{
		padding: 35px 0;
	}
	.google-map{
        width: 100%;
        height: 400px;
	}

	label {
		text-align: left;
		   padding-left: 0;
		@media screen and (min-width:$medium-width) {
    		text-align: right;
    	}
    }
    .selectbox{
    	padding: 0; 
    	select{
    		width: 100%;
    	}
    }
    textarea{
    	height: 105px;
    }
    .wysiwyg-content{
		padding-bottom: 40px;
        text-align: left;
	}    
	
}

.need-help {
	background: $color-primary;
	color: #FFF;
	display: inline-block;
	height: 50px;
    margin: 10px 0 10px 30px;
	padding: 5px 10px 5px 50px;
	position: relative;
	white-space: nowrap;
	border-radius: 0 10px 10px 0;
	.fa {
		color: #FFF !important;
		background: $color-primary;
		border: 5px solid #FFF;
		border-radius: 50%;
		height: 60px;
		left: -30px;
		line-height: 50px;
		margin: 0;
		position: absolute;
		text-align: center;
		top: -5px;
		width: 60px;
	}
}