// ==========================================================================
// Mixins
// ==========================================================================


///
/// Change placeholder color
/// --------------------------------------------------
///
/// @example scss - Usage
///   input { 
///     @include placeholder { color: red; } 
///   }  

@mixin placeholder {
  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";

  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder { @content; }
  }
}


// Opacity /w ie fallback
// @include opacity(1);

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// Grayscale
// @include grayscale()

@mixin grayscale(){
  //Internet Explorer 6 - 9
  filter: gray;
  
  //Chrome 19+
  -webkit-filter: grayscale(100%);
  
  //Firefox 10+
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  
  //Standard
  filter: grayscale(100%);
}


// Clearfix
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//
// Source: http://nicolasgallagher.com/micro-clearfix-hack/

@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}


// Box shadow
// @include box-shadow(0, 1px, 2px, rgba(0, 0, 0, 0.2));

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin flex($direction: row) {
  flex-direction: $direction;
  flex-wrap: wrap;
  display: flex;
  align-content: stretch;
  align-items: stretch;
  justify-content: flex-start;
  >*{
      flex-grow: 0;
      flex-shrink: 1;
      order: 0;
      flex-basis: auto;
      align-self: auto;
      float: none;
  }
}