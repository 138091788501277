  
.container-blog
{
    margin: 30px auto;
    &-aside{
        .box{
            margin-bottom: 10px;
            border: 1px solid $gray-light;
            .title{
                font-family: $font-family--secondary ;
                font-size: 25px;
                border-bottom: 1px solid $gray-light;
                padding: 5px 10px;
                color: $color-primary;
            }
            ul{
                list-style: none;
                margin: 0;
                li{
                    a{
                        padding: 5px 10px;
                        display: block;
                        &:hover{
                            background: rgba($black, 0.05);
                        }
                    }
                }
            }
            .item-bestseller{
                @include flex();
                width: 100%;
                flex-wrap: nowrap;
                padding: 5px 5px 5px 10px;
                &:hover{
                    background: rgba($black, 0.05);
                }
                cursor: pointer;
                text-align: left;
                >*:first-child{
                    flex-grow: 1;
                }
                img{
                    width: 70px;
                    margin-left: 10px;
                }
            }
        }
    }
    &-list{
        @include flex();

        .paginator{
            padding: 20px;
            text-align: center;
            white-space: nowrap;
            .page{
                display: inline-block;
                padding: 3px;
                color: $black;
                font-size: 20px;

                &.current{
                    color: $color-secondary;
                }
            }

        }

    }
    
    .blog-article{
        display: block;
        &-preview{
            text-decoration: none;
            .h1,.h2,.h3,.h4,.h5,.h6,.p{
                display: block;
                text-decoration: none;
            }
            &:hover{
                .blog-article-preview--thumbnail_img img{
                    transform: translate(-50%,-50%) scale(1.1) ;
                }

                .color--primary{
                    text-decoration:underline;
                }
            }

            &--thumbnail{
                width: calc(100% - 90px);
                float: left;
                display: block;
                &_img{
                    width: 100%;
                    padding-top:55%;
                    position: relative;
                    overflow: hidden;
                    display: block;
                    img{
                        position: absolute;
                        top:50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        -webkit-transition: all 500ms ease;
                        -moz-transition: all 500ms ease;
                        -ms-transition: all 500ms ease;
                        -o-transition: all 500ms ease;
                        transition: all 500ms ease;
                        &:hover{
                            transform: translate(-50%,-50%) scale(1.1) ;
                        }
                    }
                }
                +time{
                    width: 90px;
                    float: left;
                    background: $color-primary;
                    text-align: center;
                    color: $white;
                    padding: 10px;
                    span{
                        display: block;
                        white-space: nowrap;
                    }
                    .day{
                        font-size: 25px;
                    }
                }
            }

            .h2{
                color:$black;
                padding: 0;
                margin: 10px 0;
            }

            .p{
                color:$black;
                margin: 10px 0;
            }
            margin-bottom: 75px; ;
        }

        &-related{
            .slick-slider{
                padding:0;
            }
            .slick-track
            {
                display: flex !important;
            }

            .slick-slide
            {
                height: inherit !important;
            }
            .slick-slide{
                height: 100%;
                padding: 0 10px;
            }
            &_article{
                display: block;
                border: 1px solid;
                height: 100%;
                .title{
                    font-size: 20px;
                    color: $color-primary;
                    display: block;
                    margin: 10px 0 ;
                    padding: 10px;
                }
                .summary{
                    font-family: 16px;
                    display: block;
                    padding: 10px;
                }
                &--thumbnail{
                    &_img{
                        width: 100%;
                        padding-top:55%;
                        position: relative;
                        overflow: hidden;
                        display: block;
                        img{
                            max-width: 100%;
                            display: block;
                            margin: auto;

                            position: absolute;
                            top:50%;
                            left: 50%;
                            transform: translate(-50%,-50%);
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                            -webkit-transition: all 500ms ease;
                            -moz-transition: all 500ms ease;
                            -ms-transition: all 500ms ease;
                            -o-transition: all 500ms ease;
                            transition: all 500ms ease;
                        }
                    }
                }

                &:hover{
                    .blog-article-related_article--thumbnail_img img{
                        transform: translate(-50%,-50%) scale(1.1) ;
                    }
                }
            }

            @media screen and (min-width:$medium-width ){
                .slick-slider{
                    padding:0 100px;
                }
            }
        }
    }
}
