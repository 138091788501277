.application-tree {
	color: $white;
	font-size: em(16px);

	@media screen and (min-width: $medium-width){
		font-size: em(14px);
	}
	
	&__form {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	&__item { } // li

	&--depth1 { 
		/*padding-top: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid $gray-light;*/
	}
	&--depth2 { padding-left: 10px; }
	&--depth3 { padding-left: 20px; }
	&--depth4 { 
		padding-left: 30px; 
		font-size: em(16px);
		font-weight: $font-weight--bold;
		text-transform: uppercase;

		[type="checkbox"] {
			margin-right: 5px;
		}
	}


	.group-label {
		@extend %table;
		width: 100%;
		padding: 5px;
		cursor: pointer;

		

		&__label {
			display: block;
			margin-left: 45px;
			margin-right: 8px;
		}

		&__check {
			float: left;
			margin-top: 3px;
		}

		&__icon {
			display: inline;
			float: left;
			margin-right: 10px;
			font-size: em(18px);
		}
	}

	// Layout, need refactor

	.part-type-wrap {
		margin-top: 30px;
		margin-bottom: 30px;
	}


	// Actions

	.has-selected { 
		> .group-label {
			background: $color-primary; 
		}
	}


	// Reset

	ul {
		@extend %unlist;
	}

	li {
		list-style:none; 
	}

	input[type="checkbox"] {
	    transform: scale(1.2);
	}
}