.container-partial { 
    &--your-promo { 
       
        padding-bottom: 20px;
        @media screen and (min-width:$medium-width) {
            padding-top: inherit;
        }
        button {
            display: none!important;
        }
        .container-promo img {
            width: 100%;
            max-width: 320px;
            padding: 10px;
        }
    }
    &--help{
        h2{
            font-size: 20px;
        }
    }
}
.container-promo{
    .btn{
        margin-top: 0;
    }
    input.email {
        display: inline-block;
        padding: 10px;
        background-color: #FFF;
        border-radius: 0;
        margin: 0;
        width: 50%;
        min-width: 100px;
        font-family: $font-family--secondary;
        @media screen and (min-width:$medium-width) {
            width: 60%;
        }
    }
    .promo-image{
       width: 100%; 
    }
    strong{
        color: $color-primary;
    }
    .subscribe-newsletter{
        background-color: $gray-light;
    }
    .subtitle{
        font-weight: bold;
        color: $gray-xdark;
        font-family: $font-family--secondary;
    }
    .text{
        font-family: $font-family--secondary;
        color: $color-secondary;
        font-size: 12px;
    }
    .text-brands{
        color: $color-primary;
        font-weight: bold;
        font-family: $font-family--secondary;
    }
    .title{
        color: $gray-xdark;
        font-weight: 700;
        font-size: 40px;
       
    }
}


.container{
    &-parttypes-NA, &-pdf{
        ul{
            
            @include flex;
            margin: 0;
            list-style: none;

            li{
                width: 100%;
                padding: 0 5px;
                @media screen and (min-width:$small-width ){
                    width: 33.33333%;
                }
                @media screen and (min-width:$medium-width ){
                    width: 20%;
                }
            }
            form{
                width: 100%;
                height: 100%;
            }
            button:not(.btn), a.button{
                cursor: pointer;
                &:hover{
                    box-shadow: inset 0 0 0 2px $gray-light;
                    &.box--red{
                        box-shadow: inset 0 0 0 2px $color-secondary;
                    }
                }
            }
            button:not(.btn), a.button, div.box{
                padding: 20px;
                border: 1px solid $gray-light;
                //min-height: 240px;
                height: 100%;
                font-size: 16px;
                color: #000;
                font-weight: 700;
                text-align: left;
                width: 100%;
                margin-bottom: 10px;
                display: flex;
                flex-direction: column;
                text-decoration: none;
                background: $white;

                &.box--red{
                    border-color: $color-secondary;
                }
                img{
                    width: 100%;
                    height: 90px;
                    object-fit: contain;
                    margin-bottom: 15px;
                    @media screen and (min-width:$medium-width ){
                        height: 120px;
                    }

                    &.full{
                        height: inherit;
                    }
                }
                
                .price-range{
                    font-size: 18px;
                    font-weight: normal;
                    margin-top: 10px;
                }
            }

            .item-bestseller{
                display: flex;
                flex-direction: column;
                height: 500px;
                &-title{
                    flex-grow: 1;
                    width: 100%;
                    text-align: center;
                }
                &-footer{
                    border-top:1px solid $gray-light;
                }
            }
        }
    }
}
