@media screen and (max-width: $phone-average) {
	.container-home--content {
		margin-top: 0!important;
	}
	.partial-title{
		transform: translate(-50%,0)!important;
		left: 50%;
		font-size: 20px!important;
	}
}

.slide-video{
	position: relative;
	overflow: hidden;
	&__video-voile{
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,.4);
		&__text{
			font-size: 93px;
			font-weight: bold;
			width: 50%;
			max-width: 625px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-100%,-50%);
		}
	}
	&__video{
		margin-top: -250px;
	}
}
