.btn-results {
    position: relative;
    .loading-icon {
        position: absolute;
        top: 15%;
        left: 60%;
    }
    &.disabled{
        background: $gray-light!important;
    }
}
.container-step2 {
    background-color: white;
    padding: 0;
    @media screen and (min-width: $medium-width) {
        padding: inherit;
    }
    .brands .container-brand, .brands .container-letter-separator {
        display: none;
        width: calc((100% / 10) - 14px);
        height: 150px;
        margin: 5px!important;
        @media screen and (max-width: $container-width) {
            width: calc((100% / 6) - 14px);
            height: 120px;
        }
        @media screen and (max-width: $phone-average) {
            width: calc((100% / 3) - 14px);
            height: 120px;
        }

        h3 {
            padding: 15px;
        }
        .letter-separator {
            padding: 15px 0;
        }
    }

    .container-fluid{
        padding: 0;
    }
    .container-step2 {
        background-color: $white;
        padding: 0px;
        @media screen and (min-width: $medium-width) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .dropdown-manufacturer-label{
        display: block;
        padding-left: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        @media screen and (min-width: $medium-width) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    .dropdown-manufacturer-chk{
        float: left;
        margin-top: 10px;
        @media screen and (min-width: $medium-width) {
            margin-top: 3px;
        }
    }

    .left-side {
        padding: 0;
        @media screen and (min-width: $medium-width) {
            //padding: 20px;
          }
        .categories {
            font-family: $font-family--secondary;
            font-size: 15px;
            background-color: $gray-xlight;
            ul li {
                padding: 16px 10px;
                @media screen and (min-width: $medium-width) {
                    padding: 4px;
                }
            }
            > ul > li {
                border-bottom: 1px solid $gray-light;
                list-style: none;
            }
            .manufacturer {
                margin-left: 25px;
            }
            .subcategories{
                li{
                    padding: 2px 10px;
                }
            }
            .subcategories,
            .parttypes,
            .manufacturers {
                margin-left: 15px;
                li {
                    list-style: none;
                }
            }
            .subcategories > li > .submenu-handle > label {
                color: $black;
            }
            .manufacturer label {
                color: $black;
            }
            .submenu-handle {
                display: block;
                cursor: pointer;
                margin-left: 15px;
                padding-bottom: 10px;
                padding-top: 10px;
                width: 95%;
                input, i {
                    font-style: normal;
                   float: left; 
                }
                i{
                    margin-right: 5px;
                }
                label{
                    word-break: break-word;
                    display: block;
                    padding-left: 30px;
                }
            }
        }
        .chk-selected {
            font-weight: bold; 
        }
        .container-partial--latest-promo .container-promo img {
            width: 100%;
            padding: 10px;
        }
        .container-select-jobs,{
            
            padding: 0px 13px 17px;
             @media screen and (min-width: $medium-width) {
                background-color: $gray-xlight;
                border: 1px solid $gray-light;
                margin-top: 15px;
            }
            h3{
                padding: 10px 0;
            }
        }
        .container-category {
            h3 {
                padding: 15px;
            }
            
        }
        .container-select-keyword{
            padding: 0 13px 17px;
           
            .select2-selection__arrow b{
                display: none;
            }
        }
        .jobs-select{
            font-family: $font-family--secondary;
            font-size: 14px;
            padding: 8px 10px;
            height: 40px;
        }

        .parttype-select {
           padding: 8px 10px;
           height: 40px;
           font-family: $font-family--secondary;
           font-size: 14px;
       }
        .select2-container--default{
            &:after{
                border-color: #888 transparent transparent;
                border-style: solid;
                border-width: 5px 4px 0;
                content: ' ';
                height: 0;
                right: 7px;
                position: absolute;
                top: 34%;
                width: 0;
            }
           
             .select2-results>.select2-results__options {
                max-height: 1000px;
            }
        }
        .select2-dropdown{
            nax-height: 400px;
        }
        .select2-selection--single{
            height: inherit;
            //margin: 0 12px;
        }
        
    }
    .dropdown-template {
        position: absolute;
        display: block;
        height: 300px;
        overflow-y: scroll;
        border: 1px solid #c6c6c6;
        background-color: #fff;
        z-index: 99;
        width: 85%;
        @media screen and (min-width: $medium-width) {
            width: 500px;  
        }

        .dropdown-template-row {
            padding: 5px 10px;
            span {
                font-family: $font-family--secondary;
                font-size: 13px;
            }
        }

    }
    h3 {
        color: #565656;
        padding: 15px 0;
        margin-bottom: 0px;
    }
    h4 {
        padding: 10px;
        font-family: $font-family--secondary;
        display: inline-block;
    }
    label {
        font-family: $font-family--secondary; 
    }
    .right-side {
        padding: 0;
          @media screen and (min-width: $medium-width) {
            padding: 11px;
          }
        .chk {
            width: 18px;
            height: 18px;
        }
        .container {
            padding: 0;
            @media screen and (min-width: $medium-width) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
        .container-buttons{
            background-color: $white;
            @extend %transition-base;
            margin-top: 15px;
               @media screen and (min-width: $medium-width) {
                    margin-top: 0;
                    
               }
        }
        .container-search-part-number{
            background-color: $gray-xlight;
            border: 1px solid $gray-light;
            padding: 10px; 
            margin-top: 15px;
             @media screen and (min-width: $medium-width) {
                 margin-top: 0px;
             }
             @media screen and (min-width: $medium-width) {
                 margin-top: 15px;
             }
              .go-button-validate{
                    color: $gray-base;
                    padding: 10px 20px;
                    font-weight: 700;
                    margin-top: 0;
                    text-transform: uppercase;
                    background-color: $gray-light;
                    @media screen and (min-width: $medium-width) {
                         width: inherit;
                    }
                    &.valid{
                        background-color: $color-primary;
                        color: $white;
                        &:hover{
                            cursor: pointer;
                        }
                    }
                }
            h3{
                padding-bottom: 10px!important;
            }
            input[type=text]{
                background-color: white;
                padding: 11px 16px;
                font-family: $font-family--secondary;
                font-size: 14px;
                width: 85%;
                margin-bottom: 5px;
                @media screen and (min-width: $medium-width) {
                    width: -moz-calc(100% - 65px);
                    width: -webkit-calc(100% - 65px);
                    width: -o-calc(100% - 65px);
                    width: calc(100% - 65px);
                }
            }
        }
        .container-toggle-applicative {
            font-size: 14px;
            padding: 5px;
            @extend %transition-base;
            @media screen and (min-width: $medium-width) {
                background-color: $gray-xlight;
                border: 1px solid $gray-light;
                height: 100px;

            }
             @media screen and (min-width: $medium-width) {
                margin-top: 15px; 
            }
            .toggle-button {
                text-align: center;
                font-size: 14px;
                margin-top: 15px;
                 @media screen and (min-width: $medium-width) {
                    margin-top: 25px;
                   
                 }
                @media screen and (min-width: $medium-width) {
                     font-size: 16px;
                }
                &--text{

                    padding: 5px;
                    font-size: 16px;
                    @media screen and (min-width: $medium-width) {
                        top: 50%;
                        position: relative;
                        transform: translateY(-50%);
                    }
                }
            }
        }
        .container-brands{
            padding: 0 10px;
            .title {
                text-align: center;
                margin-top: 20px;
                @media screen and (min-width: $medium-width) {
                    margin-top: 0;
                    text-align: left;
                }
            }
         
        }
        .container-button {
            display: none;
            @media screen and (min-width: $medium-width) {
                display: block;
            }
            @media screen and (min-width: $medium-width) {
                padding-left: 20px;
                padding-right: 20px; 
            }
            button {
                width: 98%;
                margin: 0;
                height: 80px;
                background-color: $color-primary;
                font-family: $font-family--secondary;
                text-transform: uppercase;
                text-align: left;
                color: #fff;
                font-size: 21px;
                padding: 10px;
                cursor: pointer;
                background-size: 25%;
                background-repeat: no-repeat;
                background-position: right 10px center;
                @extend %transition-base;
                    @media screen and (min-width: $medium-width) {
                        width: 98%;
                        height: 100px;
                        padding-top: 50px;
                        text-align: center;
                        background-position: center top 10px;
                        margin: 0;
                    }
                    @media screen and (min-width: $medium-width) {
                        padding: 12px 0 12px 22px;
                        text-align: left;
                        background-position: right 10px center;
                        margin: 15px 0 0;
                        max-height: 65px;
                    }
                i {
                    padding-right: 10px;
                }
            }
            button.disabled {
                cursor: default;
                background-color: $gray-xlight;
                color: #dcdcdc!important;
                &:after{
                    border-color: #dcdcdc;
                }
            }
        }
        .container-toggle-and-buttons{
            margin: 0;
        }
        .fixed-element{
            top: 0px;
            z-index: 98;
            padding-bottom: 10px;
            width: 89%;
            @extend %transition-base; 
            @media screen and (min-width: $medium-width) {
                width: 90%;  
            }
            @media screen and (min-width: $medium-width) {
                top: 0;
                padding: 0;
                padding-bottom: 10px; 
                width: 71.1%;  
            }
            .container-toggle-and-buttons{
                margin: auto;
                padding: 0;
            }
             .container-toggle-applicative, .container-search-part-number{
                @media screen and (min-width: $medium-width) {
                    display: inline-block;
                    margin-top: 10px;
                }
                @extend %transition-base;
             }
             .container-button{
                    margin-top: 10px;
                    @media screen and (min-width: $medium-width) {
                        margin-top: 0;
                    }
                    @media screen and (min-width: $medium-width) {
                         margin-top: 10px;
                    }
                 @extend %transition-base; 
            }
            .container-button button{
                margin-top: 0;
                 @extend %transition-base; 
            }
            .switch{
                display: block;
                margin-right: 10px;
                margin-left: auto;
                margin-right: auto;
               
            }
          
        }
        .manufacturers {
            .manufacturer {
                display: inline-block;
                border: 1px solid $gray-light;
                text-align: center;

                .container-droplist-part-type {
                    color:transparent;
                    top: -10px;
                    height: 30px;
                    position: relative;
                    margin-top: 1px;
                    &:hover{
                        cursor: pointer;
                    }
                }
                .dropdown-icon {
                    position: absolute;
                    bottom: 10px;
                    right: 5px;
                    cursor: pointer;
                }
                img {
                    width: 90%;
                    max-height: 90%;
                }
                input[type=checkbox] {
                    position: absolute;
                    top: 5px;
                    left: 5px;
                }
                span {
                    display: block;
                    text-align: center;
                    font-family: $font-family--secondary;
                    text-transform: uppercase;
                    font-size: 10px;
                    font-weight: bold;
                    position: absolute;
                    bottom: 8px;
                    padding-left: 5px;
                }
            }
        }
        .parttype-pop-header, .parttype-pop-footer{
            display: none;
        }
        .parttype-pop-footer {
            &:hover  {
                cursor: pointer; 
            }
        }
    }
    select {
        border: 1px solid #c6c6c6;
        -webkit-appearance: menulist;
        width: 100%;
        background-color: white;
        padding: 16px 10px;
         @media screen and (min-width: $medium-width) {
             padding: 8px 10px;
         }        
    }
    .switch {
      position: relative;
      display: block;
      width: 60px;
      height: 34px;
      vertical-align: middle;
      margin-bottom: 10px;
      margin-right: 10px;
      margin-left: auto;
      margin-right: auto;
     
    }
    .switch input {
        display:none;
    }
    .slider {
        position: absolute;
         cursor: pointer;
         top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $shark;
        @extend %transition-base;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        @extend %transition-base;
    }

    input:checked + .slider {
        background-color: $color-primary;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
    
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
} 

.parttype-pop-header{
     .override-color-gray:before{
        color : $gray-base;
    }
}
.title--step {
    text-align: center; 
    background-color: $gray-xlight;
    color: $color-primary;
    font-size: 45px;
    text-transform: uppercase;
    padding-bottom: 5px;
    letter-spacing: 2px;

    .color--secondary {
        display: block;
        @media screen and (min-width: $medium-width) {
            display: inline-block;
        }
    }
    &__text{
        background-color: $gray-xlight;
        padding: 10px;
    }
}


.btn-mobile-reset, .btn-mobile-getresults, .btn-mobile-back {
    background-color: $color-primary;
    width: 50px;
    height: 50px;
    color: $white;
    font-size: 20px;
    text-align: center;
    position: fixed;
    right: 140px;
    bottom: 10px;
    padding-top: 13px;
    z-index: 50;
    border-radius: 50%;
    box-shadow: 0 3px 5px rgba(0,0,0,.5);
    @media screen and (min-width: $medium-width) {
        display: none;
    }
    &.disabled {
        background-color: $gray-light;
    }
    &:hover {
        cursor: pointer;
    }
    
}
.btn-mobile-reset {
    right: 200px;
}

.btn-mobile-back {
    left: 16px;
}

.six-row-displayer{
    background: #333;
    width: calc(100% - 10px);
    overflow: hidden;
    height: 0;
    transition: height .5s ease;
    float: left;
    @include flex();
    display: inline-flex;
    &.active{
        height: auto;
        margin: 5px 6px;
        padding: 30px;
    }
}

.three-row-displayer{
    height: 0;
    overflow: hidden;
    @include flex();
    display: inline-flex;

}

.two-row-displayer{
    height: 0;
    overflow: hidden;
    display: none;
}
.ten-row-displayer{
    display: none;
}

@media screen and (min-width: $container-width + 1) {
    .ten-row-displayer{
        background: #333;
        width: calc(100% - 10px);
        overflow: hidden;
        height: 0;
        transition: height .5s ease;
        float: left;
        @include flex();
        display: inline-flex;
        &.active{
            height: auto;
            margin: 5px 6px;
            padding: 30px;
        }
    }

    .manufacturers .six-row-displayer{
        display: none;
    }
}
@media screen and (min-width: $medium-width + 1) {
    .three-row-displayer{
        display: none;
    }
}
@media screen and (max-width: $medium-width) {
    .six-row-displayer{
        display: none;
        padding: 0;
    }
    .ten-row-displayer{
        display: none;
        padding: 0;
    }

    .three-row-displayer{
        background: #333;
        width: calc(100% - 10px);
        overflow: hidden;
        height: 0;
        transition: height .5s ease;
        float: left;
        &.active{
            height: auto;
            margin: 5px 6px;
            padding: 30px;

        }
    } 

    .manufacturers{
        .three-row-displayer{
            &,&.active{
                display: none;
            }
        }
        .six-row-displayer{
            display: block;
            &.active{
                padding: 10px;
                display: block;
            }
        }
    }
}

@media screen and (max-width: $phone-average) {
    .three-row-displayer{
        //height: 0!important;
        display: none;
        padding: 0;
    }


    .two-row-displayer{
        background: #333;
        width: calc(100% - 10px);
        overflow: hidden;
        height: 0;
        transition: height .5s ease;
        float: left;
        display: block;
        &.active{
            height: auto;
            margin: 5px 6px;
            padding: 10px;
        }
    }

    .manufacturers{
        .six-row-displayer{
            &,&.active{
                display: none;  
            }
        }
        .three-row-displayer{
            display: block;
            &.active{
                padding: 10px;
                display: block;
            }
        }
    }
}


.ten-row-manufactirer-displayer{
    background: #333;
    width: calc(100% - 10px);
    overflow: hidden;
    height: 0;
    transition: height .5s ease;
    float: left;
    @include flex;
    display: inline-flex;


    &.active{
        height: auto;
        margin: 5px 6px;
        padding: 30px;
    }
}

.six-row-manufactirer-displayer{
    height: 0;
    overflow: hidden;
    display: none;
}

.three-row-manufactirer-displayer{
    height: 0;
    overflow: hidden;
    display: none;
}


@media screen and (max-width: $container-width) {
    .ten-row-manufactirer-displayer{
        height: 0!important;
        display: none;
        padding: 0;
    }

    .six-row-manufactirer-displayer{
        @include flex;
        display: inline-flex;
        background: #333;
        width: calc(100% - 10px);
        overflow: hidden;
        height: 0;
        transition: height .5s ease;
        float: left;

        &.active{
            height: auto;
            margin: 5px 6px;
            padding: 30px;

        }
    }
}

@media screen and (max-width: $phone-average) {
    .six-row-manufactirer-displayer{
        height: 0!important;
        display: none;
        padding: 0;
    }


    .three-row-manufactirer-displayer{
        display: inline;

        background: #333;
        width: calc(100% - 10px);
        overflow: hidden;
        height: 0;
        transition: height .5s ease;
        float: left;
        &.active{
            height: auto;
            margin: 5px 6px;
            padding: 10px;
        }
    }
}

.superswitch{
    &__b2b{
        &:not(.active){
            display: none!important;
        }
        &.active{
         display: block;
        }
    }
    &__b2c{
        &:not(.active){
            display: none!important;
        }


        &.active{
            display: block;
        }
    }
}

.switch-pro-part{
    position: fixed;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    z-index: 2;
    opacity: .8;
    cursor:pointer;
    transition: all .5s ease;
    @media screen and (max-width: $phone-average) {
        top: 58px;
        right: 5px;
    }
    &:hover{
        .switch-pro-part__label-right{
            transform: translate(calc(21px - 100%),-50%);
            width: 315px;

        }
        opacity: 1;
    }
    &__container{
        margin: 0 auto;
        display: table;
    }
    &__label-right,&__label-left {
        pointer: cursor;
        height: 32px;
        transition: all .5s ease;
        position: absolute;
        padding: 5px 40px 5px 30px;
        border-radius: 20px;
        width: 0;
        left: 0;
        top: 50%;
        transform: translate(0,-50%);
        color: #000;
        z-index: 1;
        border: 1px solid #777;
        background: #fff;
        text-transform: uppercase;
        overflow: hidden;
    }
    &__button {
        float: left;
        border: 2px solid #000;
        background: $color-primary;
        padding: 21px;
        border-radius: 60px;
        z-index: 2;
        position: relative;
        img{
            max-width: 60px;
        }
    }
}
