.brands {
    .container-brand {
      height: 110px;
      background-color: $white;
      padding: 0;
      border: 1px solid #cccecd;
      cursor: pointer;
      @media screen and (min-width: $medium-width) {
             margin: 5px;
             border: 0;
         }
         @media screen and (min-width: $medium-width) {
            margin: 10px;
         }
        &:hover{
          .brand-item{

            -moz-transform: translate(-50%,-50%) scale(0.95)!important;;
            -webkit-transform: translate(-50%,-50%) scale(0.95)!important;;
            transform: translate(-50%,-50%) scale(0.95)!important;
          }
        }
        .brand-item {
          width: 100%;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100%;
          height: 100%;
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%) scale(.8);
          transition: all .5s ease;
          position: absolute;
        }
    }
    .container-letter-separator {
        position: relative;
        height: 110px;
        background-color: $gray-light;
        border: 1px solid #cccecd;
         @media screen and (min-width: $medium-width) {
             margin: 5px;
         }
        @media screen and (min-width: $medium-width) {
            margin: 10px;
         }
         .letter-separator {
            left: 0;
            right: 0;
            position: absolute;
            text-align: center;
            padding: 5px 0;
            color: $shark;
            font-size: 85px;
            font-weight: bold;
        }
    }
    .paginator {
        display: block;
        margin: 15px auto;
        .page {
            float: left;
            padding: 5px;
            cursor: pointer;
            &.selected {
                cursor: default;
                font-weight: bold;
                font-size: 20px;
                padding-top: 2px;
                padding-bottom: 0;
            }
        }
        .separator {
            float: left;
            padding: 5px 10px;
            color: $white;
        }
    }
}


.page-template-tpl-brands-php{
    .brands {
        .container-brand {
              @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        top: -20px;
                    }
        }
    }
}
