.page-warning {
    padding: 60px;
    text-align: center;
    h1{
        color: $shark;
        padding-bottom: 20px;
        font-size: 45px;
    }
    p{
        color: $shark;
        font-family: $font-family--secondary;
    }
	&__404 {

	}
    &__403 {

    }
}

.container-warning{
    background: $gray-xlight;
    color: $black;
    text-align: left;
    @include flex();
    flex-wrap: nowrap;
    padding: 5px;
    &-icon{
        font-size: 70px;
        padding: 0 15px;
        align-self: center;
        color: $gray-base ;
        //float: left;
    }
    &-text{
        p{
            color: $black;
            margin: 5px 0;
            a[href]{
                color: $color-primary;
                font-style: italic;
            }
        }
        
    }
}