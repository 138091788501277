// ========================================================
// Wysiwyg Components
// ========================================================

.wysiwyg-container,
.wysiwyg-content,
body#tinymce.wp-editor,
.mce-content-body, {
    @extend %clearfix;
    @media screen and (min-width: 850px) {
        padding-bottom: 0;
    }

    //padding: $spacing-base-vertical;

    //height: 100%;
    
	background-color: $white;
    position: relative;

	font-family: $font-family--primary;
    
    width: 100%;
    color: $base__font-color;

	h1, .h1 {
		color: $base__font-color;
		font-weight: bold;
		font-size: 40px;
		margin: 1em 0 0.5em 0;
		letter-spacing: $letter-space;
		line-height: 42px;
		text-transform: uppercase;
	}

	h2, .h2 {
		color: $base__font-color;
		font-weight: normal;
		font-size: em(30px);
		text-transform: uppercase;
		letter-spacing: $letter-space;
		margin: 1em 0 0.5em 0;
		line-height: 40px;
	}

	h3, .h3 {
		color: $base__font-color;
		font-size: em(22px);
		text-transform: uppercase;
		margin: 1em 0 0.5em 0;
		letter-spacing: $letter-space;
		line-height: 40px;
	}

	h4, .h4 {
		color: $base__font-color;
		font-size: em(18px);
		line-height: 20px;
		letter-spacing: $letter-space;
		font-weight: normal;
		margin: 1em 0 0.5em 0;
	}

	p {
		color: $base__font-color;
		font-size: em(16px);
		line-height: $base__line;
		letter-spacing: $letter-space;
		margin-bottom: 1em;

		&.white { color: $white; }
		&.black { color: $black; }
		&.bolder { font-weight: bold; }
	}

	a {
		color: $color-primary;
        font-weight: 500; 
        font-size: em(16px);
        text-decoration: none;
        transition: all 250ms ease;
		letter-spacing: $letter-space;
 
		&:active,
		&:focus,
		&:visited {
            color: $color-primary--lighten;
            text-decoration: none;
		}
        
        @media only screen and (min-width: $nohover-width) {
            &:hover {
                color: rgba($color-primary--darken, 0.8);
            }
        }
	}

    ul {
        color: $base__font-color;
        padding-left: 0;
        list-style-type: none;
        overflow: hidden;
        li {
            position: relative;
            padding-left: 30px;
            margin-bottom: 10px;

            &:before {
                position: absolute;
                content: "";
                left: 7px; top: 10px;
                width: 5px;
                height: 2px;
                background-color: $color-primary;
            }

            >ul{
                margin-top: 10px;
            }
        }

    }
    ol {
        font-size: 1em;
        color: $gray-base;
        padding: 0;
        margin: 0 0 20px 0;
        list-style-type: none;
        
        li {
            counter-increment: step-counter;
            position: relative;
            padding: 0 0 0 30px;
            margin-bottom: 10px;
            
            &:before {
                position: absolute;
                left: 0;
                top: 2px;
                content: counter(step-counter);
                font-size: 16px;
                //background-color: $color-primary;
                color: $color-primary;
                width: 20px;
                height: 20px;
                display: inline-block;
                text-align: center;
            }
        }
    }

    blockquote,
    blockquote p {
        font-size: 28px;
        line-height: 32px;
        text-align: center;
        color: $gray-dark;
        margin: 40px 0;
        position: relative;
        padding: 20px 0;
    }
    
    blockquote p {
        margin: 0;
    }
    
    blockquote:before, 
    blockquote:after {
        content: '';
        background: $gray-dark;
        height: 4px;
        position: absolute;
        width: 60%;
        left: 50%;
        margin-left: -30%
    }
    
    blockquote:before { top: 0; }
    
    blockquote:after { bottom: 0; }
    
    img { 
        @extend .img-responsive;
    }
    
    a.btn, .btn {
        color: $white;
        @media only screen and (min-width: $nohover-width) {
            &:hover {
                color: $white;
            }
        }
    }
    
    .media-container {
        position:relative;
        margin-bottom:30px;
    }

    iframe{
        max-width: 100%;
    }

    &--bordered {
        border: 1px solid $gray-base;
        padding: 15px;
    }

    /* == WordPress WYSIWYG Editor Styles == */

    .entry-content img {
        margin-bottom: 1.5em;
        }
    .alignleft, img.alignleft {
        margin-bottom: 1.5em;
        }
    .alignright, img.alignright {
        margin-bottom: 1.5em;
        }
    .aligncenter, img.aligncenter {
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 1.5em;
        display: block;
        clear: both;
    }
    .alignnone, img.alignnone {
        /* not sure about this one */
    }
    @media screen and (min-width: 600px){
        .alignleft, img.alignleft {
            margin-right: 1.5em;
            display: inline;
            float: left;
            }
        .alignright, img.alignright {
            margin-left: 1.5em;
            display: inline;
            float: right;
            }
        .aligncenter, img.aligncenter {
            margin-right: auto;
            margin-left: auto;
            display: block;
            clear: both;
            }
        .alignnone, img.alignnone {
            /* not sure about this one */
            }
    }
    .wp-caption {
        margin-bottom: 1.5em;
        text-align: center;
        padding-top: 5px;
        }
    .wp-caption img {
        border: 0 none;
        padding: 0;
        margin: 0;
        }
    .wp-caption p.wp-caption-text {
        line-height: 1.5;
        font-size: 10px;
        margin: 0;
        }
    .wp-smiley {
        margin: 0 !important;
        max-height: 1em;
        }
    blockquote.left {
        margin-right: 20px;
        text-align: right;
        margin-left: 0;
        width: 33%;
        float: left;
        }
    blockquote.right {
        margin-left: 20px;
        text-align: left;
        margin-right: 0;
        width: 33%;
        float: right;
        }
    .gallery dl {}
    .gallery dt {}
    .gallery dd {}
    .gallery dl a {}
    .gallery dl img {}
    .gallery-caption {}

    .size-full {}
    .size-large {}
    .size-medium {}
    .size-thumbnail {}

}

.tableizer-table{
    min-width: 100%;
    text-align: left;
    thead{
        background-color: $gray-light;
        color: $black;
    }
    th,td{
        padding: 5px 10px;
    }
    tbody{
        tr:not(:first-child){
            th,td{
                border-top: 1px solid $gray-base;
            }
        }
    }
}

.gallery { 
    margin: 10px auto 20px; 
    margin: 10px auto 20px;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-pack: start;
    justify-content: flex-start;
    .gallery-item {
        //float: left;
        margin-top: 0;
        text-align: center;
        width: 100%;
        padding: 10px;
    }
    @media screen and (min-width: 400px){
        .gallery-item {
            width: 50%;
        }
    }
    @media screen and (min-width: 600px){
        .gallery-item {
            width: 33%;
        }

        &.gallery-columns-2 {
            .gallery-item { width: 50%; }
            .attachment-medium {
                max-width: 92%;
                height: auto;
            }
        }
        &.gallery-columns-4{ 
            
            .gallery-item { width: 25%; }
            .attachment-thumbnail {
                max-width: 84%;
                height: auto;
            }
        }
    }

    
    .gallery-caption {
        color: #888;
        font-size: 12px;
        margin: 0 0 12px;
    }
    dl, dt { margin: 0; }
    br+br { display: none; }

    img {
        padding: 4px;
        background: $color-primary;
        transition: 500ms ease all;
        max-width: 100%;
        height: auto;
        border: 0 !important;
        &:hover {
            transform: scale(1.05);
            //background: white;
        }
    }

    &-slider{
        margin: 0 !important;
        position: relative;
        .slick-arrow {
            position: absolute;
            top: 45%;
            cursor: pointer;
            outline: 0;
            z-index: 2;
            &.slick-prev{
                left: 70px;
            }

            &.slick-next{
                right: 70px;
                transform: scaleX(-1);
            }
        }

        img{
            width: 100%;
            object-fit: scale-down;
        }
        .slick-slide{
            padding: 10px;
        }
    }


}

.video-player{
    max-width: 530px;
    background: $black;
    border: 4px solid desaturate($color-primary,10%);
    position: relative;
    //box-shadow: 0px 13px 30px -7px black;
    margin: auto;
    .holder{
        position: relative;
        padding-top: 52%;
        overflow: hidden;
        display: block;
        /*img{
            width: 100%;
            height: auto;
            position: absolute;
            top: 0;
            left: 0;
        }*/
        img{
            position: absolute;
            top:50%;
            left: 50%;
            transform: translate(-50%,-50%);
            object-fit: cover;
            width: 100%;
            height: 100%;
            -webkit-transition: all 500ms ease;
            -moz-transition: all 500ms ease;
            -ms-transition: all 500ms ease;
            -o-transition: all 500ms ease;
            transition: all 500ms ease;
            &:hover{
                transform: translate(-50%,-50%) scale(1.1) ;
            }
        }
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .cta-play{
        position: absolute;
        left: 10px;
        bottom: 10px;
        font-family: $font-family--secondary;
        color: $white;
    }
    .cta-share{
        position: absolute;
        right: 10px;
        bottom: 10px;
        font-family: $font-family--secondary;
        color: $white;
    }
    .text-top, .text-bottom{
        position: absolute;
        left:0;
        width: 100%;
        text-align: center;
        padding: 5px;
        font-weight: bold;
    }
    .text-top{
        top:0;
        background: rgba($white,0.7);
        color:$black;
    }
    .text-bottom{
        bottom:0;
        background: rgba($black,0.7);
        color:$white;
    }
    
}