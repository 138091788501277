.js-container-search-engine{
    z-index: 1;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    width: auto;
    right: calc(50% - 656px);
    @media screen and (max-width: $container-width) {
        right: 14px;
    }
    @media screen and (max-width: $medium-width - 1px) {
        position: relative;
        margin: 0;
        padding: 0;
        transform: none;
        margin: 14px 0 20px;
        left: 0;
        width: 100%;
        .container-search-tool--banner, .container-search-tool--modal {
            width: 100%;
        }
    }
}
.container-search-tool--banner{
    min-height: 351px;
}
.container-search-tool--banner, .container-search-tool--modal{

    padding: 10px;
    left: 0;
    background-color: #313236;
    z-index: 50;
    width: 424px;
    border: 1px solid $color-primary;
    border-radius: 20px;
    margin: unset;
    right: 100px;

    .nav{
        position: absolute;
        left: 20px;
        top: 0;
        transform: translateX(-100%);
        border-radius: 20px 0 0 20px;
        z-index: -1;
        background-color: red;
        a{
            opacity: 0.3;
            -webkit-transition: all 500ms ease;
            -moz-transition: all 500ms ease;
            -ms-transition: all 500ms ease;
            -o-transition: all 500ms ease;
            transition: all 500ms ease;
            &:hover{
                opacity: 1;
            }
        }
        img{
            width: 50px;
        }
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            li{
                border-bottom: 1px solid rgba($white,0.5);
                padding: 10px 30px 10px 10px;
                &:last-child{
                    border-bottom: 0;
                }
                &.active{
                    a{
                        opacity: 1;
                        filter: brightness(1);
                    }
                }
            }
        }
        @media screen and (max-width: $medium-width - 1px) {
            position: static;
            left: 0;
            top: 0;
            transform: none;
            margin: -11px -11px 0;
            border-radius: 15px 15px 0 0;
            ul{
                @include flex;
                flex-wrap: nowrap;
                li{
                    border-bottom: 0px;
                    padding: 10px;
                    flex-grow: 1;
                    text-align: center;
                }
            }
        }
    }

    &.brand, &.promo{
        top: inherit;
        position: relative;
        display: block;
        margin-top: 60px;
        width: 100%;
        left: 0;
        transform: none;
        z-index: auto;
        .tab-menu{
            margin-left: 0;
        }

    }
     .btn-download{
        background: transparent;
        border: 1px solid $color-primary;
     }

    .select2-selection__arrow b{
        display: none;
    }
        

    .container-partial--search-engine {
        padding: 1px 0 0;  
           
        @media screen and (min-width:$medium-width) {
            background-color: transparent;
            min-height: inherit; 
        }
        @media screen and (min-width: $medium-width) {
        }
        button {
            text-transform: uppercase;
            @extend %transition-base;
           
            &:hover {
                cursor: pointer;
                @extend %transition-base;
                color: $white;
                background-color: $color-primary;
            }
        }
        .border-bottom-0{
            @media screen and (min-width: $medium-width) { 
                border-bottom-width: 0!important;
            }
        }
        .border-bottom-1{
            @media screen and (min-width: $medium-width) {
                border-bottom-width: 1px!important;
            }
        }
        .container-form .selectbox{


            @media screen and (min-width:$medium-width) {          
                
            }
            @media screen and (min-width: $medium-width) {  
                width: calc((100%/3) - 10px);
                margin-bottom: 0;        
                width: 100%;
            }
             &:after{
                border-color: #888 transparent transparent;
                border-style: solid;
                border-width: 5px 4px 0;
                height: 0;
                right: 7px;
                position: absolute; 
                top: 36%;
                width: 0;
            }
            &.container-quantity:after{
                border-style: none;
            }
            select {
                 -webkit-appearance:none;
                -moz-appearance:none;
                -ms-appearance:none;
                appearance:none;
            }
        } 
        .container-icon{
            height: 40px;
            @media screen and (min-width: $medium-width) { 
                height: 55px;
            }
            width: 90px;
            &:hover{
                cursor: pointer;
            }
         }
        .container-panel-search-engine {


            .active {
                display: block!important;
            }
            .container-heavy-trucks {
                display: none;
                .container-trucker, .container-fleet-manager {
                    display: none;
                }
            }
            .container-light-vehicles {
                display: none;
                .container-car-repair-shop, .container-motorist {
                    display: none;
                }
            }
            .container-tires, .container-wheels {
                display: none;
            }
            input[type='text'] {
                 padding: 15px 10px;
            }
           
        }
        .container-personas{
            text-align: center;
            z-index: 10;
        }
        .container-persona {
             @media screen and (min-width: $medium-width) {
                padding-top: 20px;
                float:left;
            }
             &-group{
                 display: none;
                 margin-top: 13px;
                   
                &.active{
                    display: inline-block;
                }
                 .container-persona-group-heavy-trucks{
                    display: none;
                 }
             }
            .persona__label {
                text-align: left;
                color: $white;
                display: inline-block;
                font-size: 14px;
                margin-left: 10px;
                opacity: .6;
                vertical-align: middle;
                width: 100px;
                &--big {
                    font-size: 20px; 
                    line-height: 20px;
                }
                &:hover {
                    cursor: pointer;
                }
            }
            img {
                opacity: .6;
            }
            &.active {
                .persona__label {
                    opacity: 1;
                }
                img {
                    opacity: 1;
                }
            }
        }
        .container-search-engine{
            margin: auto;
            z-index: 20;
            padding: 0 17px;
            @media screen and (min-width: $medium-width) {
                  top: inherit;
            }
            .container-search-fields{
                width: 100%;
                @media screen and (min-width: $medium-width) {
                    display: inline-block; 
                }
            }
            .selectbox{
                margin-bottom: 20px;
            }

            .tiredefinition .line{
                display: flex;
                flex-wrap: nowrap;
                margin-bottom: 20px;
                >*{
                    flex-grow: 1;
                    margin-bottom: 0;
                    &.letter{
                        flex-grow: 0;
                        padding: 0 10px;
                        line-height: 50px;
                        font-size: 28px;
                        width: 40px;
                        text-align: center;
                        background: rgba(255,255,255,0.2);
                    }
                }
            }
        }
        .divider{
            border-bottom: 1px solid $gray-base;
            height: 1px;
            width: 100%;
            @media screen and (min-width:$medium-width) {
               
            }
            @media screen and (min-width: $medium-width) {
                margin-top: 10px;
                margin-bottom: 10px; 
                width: 1px;
                height: 105px;
                margin: 0 50px;
                float: left;
                border-left: 1px solid $gray-base;
            }
        }
        .go-button, .go-button-validate{
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            padding: 16px 19px;
            background-color: $gray-light;
            color: #000;
            font-family: $font-family--secondary;
            @media screen and (min-width: $medium-width) {
                padding: 16px 19px;
            }
        }
        .go-button{
             &:hover{
                color: #FFF;
                cursor: pointer;
            }
        }
        .go-button-validate{
            color: $gray-base;
            &:hover{
                cursor: default;
                background-color: $gray-light; 
                color: $gray-base;
                }
            &.valid{
                background-color: $color-primary;
                color: $white;
                &:hover{
                    cursor: pointer;
                }
            }
        }
            @media screen and (min-width:$medium-width) {
                .input-part-number {
                    width: 100px;
                width: inherit;
            }

                border-top-left-radius: 25px;
                border-bottom-left-radius: 25px;

        }
        .container-quantity {
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;

        }
        .loading-icon{
            font-size: 16px;
            text-align: center;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-color: rgba(49,50,54,.3);
            padding: 30px;
            color: #fff;
            margin-top: 5px;
        }
        .tab-content {
            position: relative;
            color: $white;
            left:0;
            right:0;
            text-align: center;
            padding: 0 25px;
            @media screen and (min-width:$medium-width) {
                
               /* top: -20px;
                margin-bottom: -30px;*/
            }
            @media screen and (min-width: $medium-width) {
                right: inherit;
                position: relative;
                text-align: left;
            }    
            .description {

                margin: auto;
                font-family: $font-family--secondary;
                font-size: 13px;
                z-index: 2;
                padding: 0 10px;
                position: relative;
                background-color: $shark;
                display: inline-block;
                top: 5px;
                @media screen and (min-width: $medium-width) {
                    margin: inherit; 
                }
            }
            .tab-pane {
                width:100%;
                display: none;
               
                &.active {
                    display: block;
                    margin-bottom: 10px; 
                }
                 .make{
                    @media screen and (min-width: $xsmall-width) {
                        width: 100%;
                    }
                }
                .model{
                    @media screen and (min-width: $xsmall-width) {
                        width: 100%;
                    }
                }
                .search-by-garage-select{
                    @media screen and (min-width: $xsmall-width) {
                        width: 100%;
                    }
                    &:after{
                        border-color: #888 transparent transparent;
                        border-style: solid;
                        border-width: 5px 4px 0;
                        height: 0;
                        left: 50%;
                        margin-left: -4px;
                        position: absolute;
                        top: 50%;
                        width: 0;
                    }
                }
                .vin, .search-part-number-text{
                        width: -moz-calc(100% - 65px);
                        width: -webkit-calc(100% - 65px);
                        width: -o-calc(100% - 65px);
                        width: calc(100% - 65px);
                 }
            }
            .tab-pane-car-import-file .description{
                 margin-bottom: 17px;
            }
            .tab-menu {
                list-style: none;
                display: inline-flex;
                font-family: $font-family--secondary;
                margin-top: 10px;
                margin-bottom: 20px;
                width: 100%;
                .tab {
                    height: 42px;
                    text-align:center;
                    padding: 5px 10px;
                    background-color: $gray-light;
                    color: $black;
                    @extend %transition-base;
                    font-size: 10px;
                    width: calc(100% / 3);
                    flex-grow: 1;
                    &:not(:last-of-type){
                        margin-right: 2px;
                    }
                     @media screen and (min-width: $xsmall-width) {
                        font-size: 13px;
                     }
                    &:hover {
                        background-color: $gray-base;
                        color: $white;
                        @extend %transition-base;
                        cursor: pointer;
                    }
                    &.active,
                    &.active:hover {
                        background-color: $color-primary;
                        color: $white;
                    }
                    &__text{
                        transform: translateY(-50%);
                        top: 50%;
                        position: relative;
                    }
                }
            }
        }
    }
    .container-search-tool{
        padding: 0;
    }

    .selectbox .search-by-garage-select {
        height: 50px;
    }
   
    .select2-container .select2-selection--single{
        height: 50px;
    }
 
}
.container-search-tool--banner{
    .slick-lightbox-close{
        display: none;
    }
}

.select2-container--default .select2-results>.select2-results__options{
    max-height: 400px;
}


/*///////STEP2 & STEP 3///////*/

.page:not(.home) .container-search-tool--banner{
    top: 325px;
    padding-top: 20px;
    display: none;
     @media screen and (min-width: $medium-width) {
        top: 347px;
        height: 259px;
    }
 
    .border-top-half{
        display:none;
    }
    .container-form .selectbox{
        @media screen and (min-width: $medium-width) {          
            width: 33%;
        }
    }
    .container-search-engine{
        float: none;
        width: 100%;
    }
    .container-partial--search-engine{
        min-height: 210px;
        padding-top: 0;
        @media screen and (min-width: $medium-width) {
            padding-top: 10px;
        }
        .container-panel-search-engine{
            border-width: 0;
            @media screen and (min-width: $medium-width) {
                height: 140px;
            }
        }
        .tab-content .tab-pane .vin, .tab-content .tab-pane .search-part-number-text{        
            @media screen and (min-width: $medium-width) {
                width: -moz-calc(100% - 65px);
                width: -webkit-calc(100% - 65px);
                width: -o-calc(100% - 65px);
                width: calc(100% - 65px);
                padding: 15px;
            }            
        }
    }
    .container-form input[type=submit], .go-button, .search-by-garage-select, .year, .make, .model{
        @media screen and (min-width: $medium-width) {
            padding: 15px 20px;
        }
    }
    .slick-lightbox-close{
        display: block;
           top: -80px;
        
        &:hover{
            cursor: pointer;
            color: $color-primary;
            @extend %transition-base;
        }
    }
    .tab-pane-car-import-file{
        top: 0;
    }
     .tab-speed-entry{
         @media screen and (min-width: $medium-width) {
            top: 0; 
        } 
    }
    .select2-container--default{
        .select2-selection--single{
            height: inherit;
            padding: 15px 10px;
            }
        }
    .speed-entry{
        border: 0;
        .border-top-half{
            width: 25%;
        }
         .line{
            padding: 10px;
        }
    } 
    .tab-menu{
       margin-bottom: 20px;
       .tab:not(:last-of-type){
            margin-right: 4px;
       }
    }
}
.container-search-tool--overlay{
    background-color: rgba(0, 0, 0, .5);
    z-index: 999;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    display: none;
}

.container-search-tool--modal{
    display: none;
    position: fixed;
    top: calc(50%);
    transform: translateY(-50%);
    margin: auto;
    z-index: 1000;
    padding: 20px;
    width: 90%;
    left: 5%;
    padding-bottom: 30px;
    .container-search-tool{
        .container-partial--search-engine{
            padding: 0;
            .container-form .selectbox {
                width: 100%;
                @media screen and (min-width: $medium-width) {
                    width: 33%;
                }
                 @media screen and (min-width: $medium-width) {
                    width: calc((100%/3) - 10px)
                }
            }
            .tab-content{
                top: 0;
                position: relative;
                margin-bottom: -20px;
                .container-search-tool{
                    padding: 0;
                }
                .tab-pane .vin {
                    width: -o-calc(100% - 65px);
                    width: calc(100% - 65px);
                }
                .tab-menu{
                    .tab{
                        width: calc(50% );
                        margin-right: 7px;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            } 
        }

    }
     input[type='text'] {
        padding: 15px 10px;
    }
    .slick-lightbox-close{
        right: 40px;
        top: 0px;
        height: 0;
        width: 0;
        &:hover{
            cursor: pointer;
        }
    }
    select {
        padding: 16px 10px!important;
    }
    .title{
        color: $white;
    }
}
.select2-container--default{
    &.select2{
        width: 100%!important;
    }
    .select2-selection--single{
            font-family: $font-family--secondary;
            border-radius: 0;
            padding: 15px 10px;
            //height: 36px;
            font-size: 14px;
        .select2-selection__rendered{
            line-height: inherit;
            padding: 0;
            color: $gray-base; 
        }
        .select2-selection__arrow b{
            //display: none;
        }
    }
}

.select2-results__option, .select2-search__field{
    font-family: $font-family--secondary;
    font-size: 14px; 
}


/*///////CART///////*/

.container-cart .container-search-tool--banner{
    .container-personas{
        display: none;
    }
}

/*///////LOADING OVERLAY///////*/

 .loading-overlay {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #d6d6d6;
        opacity: 0.5;
        z-index: 1000;
    }
    .loading-icon-overlay{
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        text-align: center;
        .v-center{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
        p{
            margin-top: 10px;
            font-size: 25px;
            color: #000;
        }
    }
    .loading-icon-overlay i{
        position: relative;
        top: 45%;
        font-size:100px;
        color: #000;
    }